import { useCallback, useEffect } from 'react';

import { canUseDOM } from 'Shared/DOM/WindowHelper';
import { styled } from 'Theme/stitches.config';
import { useSearch } from '../../../Cms/Pages/SearchPage/Search';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import { useFilterData } from 'context/filter.context';
import SearchNoResult from 'Cms/Pages/SearchPage/SearchNoResult/SearchNoResult';

import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';

import SearchOrderSort from '../../../Cms/Pages/SearchPage/SearchOrderSort/SearchOrderSort';
import SearchResult from '../../../Cms/Pages/SearchPage/SearchResult/SearchResult';
import SearchFilterFacets from '../../../Cms/Pages/SearchPage//SearchFilterFacets/SearchFilterFacets';
import ArticleCategoryPageModel from 'Models/Pages/ArticleCategoryPage/ArticleCategoryPageModel.interface';
import HeroModule from 'DesignComponents/Organisms/Hero/HeroModule';
import { useAppSettings, useTranslations } from '../../../context/init-data.context';
import { usePageData } from '../../../context/page-data.context';

const ArticleCategoryPageWrapper = () => {
  if (!canUseDOM()) {
    return <></>;
  }

  return <ArticleCategoryPage />;
};

function ArticleCategoryPage() {
  const [, dispatchFilterData] = useFilterData();

  const isMobile = useMedia(mediaQueryTypes.bpMax720);
  const {
    staticPages: { searchPage },
  } = useAppSettings();
  const { contentArea, hero, categoryCode, numberOfProducts } =
    usePageData<ArticleCategoryPageModel>();

  const {
    searchLabels: { loadMore, hits: hitsText },
  } = useTranslations();

  const {
    result,
    noResult,
    isLoading,
    loadingMore,
    paginate,
    facets,
    sorters,
  } = useSearch(searchPage, numberOfProducts, categoryCode);

  const productHits = useCallback(() => {
    if (!result) return 0;
    return result.articleProducts?.availableItems || 0;
  }, [result]);

  // disableButton, applicable only on "Load more" button connected to Article Products result
  const disableLoadMoreArticleProductsButton = useCallback(() => {
    return (
      result?.articleProducts?.items.length ===
      result?.articleProducts?.availableItems
    );
  }, [result]);

  useEffect(() => {
    if (facets && sorters)
      dispatchFilterData({ type: 'setFacets', facets, sorters });
  }, [facets, sorters, dispatchFilterData]);

  useEffect(() => {
    dispatchFilterData({
      type: 'setQuery',
      value: '',
    });
  }, []);

  return (
    <>
      {hero && <HeroModule hero={hero} />}

      <ContentContainer>
        <LayoutWrapper>
          {result && !noResult && !isMobile && (
            <FilterContainer>
              {result.facets && <SearchFilterFacets facets={result.facets} />}
            </FilterContainer>
          )}

          <SearchResultContainer>
            {result ? (
              <>
                {!noResult && (
                  <SearchOrderSort
                    sorters={result.sorters}
                    searchHitsText={`${productHits()} ${hitsText}`}
                    searchHits={productHits()}
                    categoryCode={categoryCode}
                    facets={result.facets}
                  />
                )}
                <SearchResult
                  result={result}
                  isLoading={isLoading}
                  loadMoreObj={{
                    productHits: productHits(),
                    loadMore: loadMore,
                    disableButton: disableLoadMoreArticleProductsButton(),
                    onClick: paginate,
                    loadingMore: loadingMore,
                  }}
                  hideMagazines={true}
                  hidePages={true}
                  hideProductsHeader={true}
                />
              </>
            ) : (
              <Padding />
            )}

            {noResult && !isLoading && (
              <>
                <SearchNoResult />
              </>
            )}
          </SearchResultContainer>
        </LayoutWrapper>
        {Array.isArray(contentArea) && <ContentArea items={contentArea} />}
      </ContentContainer>
    </>
  );
}

export default ArticleCategoryPageWrapper;

const LayoutWrapper = styled('div', {
  display: 'flex',
  mt: 10,
  flexDirection: 'column',
  '@bpMin721': {
    flexDirection: 'row',
    gap: '32px',
  },
  '@bpMin1150': { gap: '80px' },
});

const FilterContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  backgroundColor: '$backgroundPrimary',
  borderBottomStyle: 'solid',
  borderBottomColor: '$searchPageBorderPrimary',
  '@bpMin721': {
    w: 260,
    pb: 12,
  },
  '@bpMin1150': { w: 260 },
});

const SearchResultContainer = styled('div', {
  pb: 10,
  w: '100%',
});

const Padding = styled('div', {
  height: '400px',
});
